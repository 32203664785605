<template>
  <div class="flex flex-col font-Inter px-10 mb-3">
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="
            mt-1
            flex flex-row
            justify-center
            items-center
            sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6
          "
        >
          <h2
            class="
              text-xl
              font-medium
              leading-7
              text-gray-900
              sm:text-2xl sm:truncate
            "
          >
            Créer un nouveau article
          </h2>
        </div>
        <div>
          <el-button
            type="primary"
            @click="createData('articleForm')"
            :loading="loading"
            :disabled="loading"
            >Enregistrer</el-button
          >
        </div>
      </div>
    </div>

    <el-form
      class="flex flex-row items-start"
      :rules="rules"
      ref="articleForm"
      :model="article"
    >
      <div class="bg-white border w-2/3 p-4">
        <h3 class="text-lg font-medium border-b pb-1 mb-2">
          Information sur l'article
        </h3>
        <div class="flex justify-between">
          <el-form-item
            class="w-1/2 mr-2"
            label="Numero de l'article (SKU)"
            prop="sku"
          >
            <el-input
              type="text"
              v-model="article.sku"
              :disabled="articleUpdate !== null"
            >
              <template slot="prepend">
                <span
                  ><label
                    v-loading="loadingSku"
                    element-loading-spinner="el-icon-loading"
                    >NO:</label
                  ></span
                ></template
              ></el-input
            >
            <span class="text-yellow-300">Vous pouvez modifier le numero</span>
          </el-form-item>
          <el-form-item
            class="w-1/2"
            label="Code barre : Code 128,EAN-13,ISMN et Autres"
          >
            <el-input type="text" v-model="article.barcode">
              <template slot="prepend">
                <i class="el-icon-c-scale-to-original"></i>
              </template>
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="Designation de l'article" prop="article_name">
          <el-input class="w-full" type="text" v-model="article.article_name" />
        </el-form-item>

        <el-form-item label="Description" prop="remark">
          <el-input
            class="w-full"
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 8 }"
            placeholder="Entrez quelque chose"
            v-model="article.remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex justify-start items-center">
            <el-switch class="mr-7" v-model="article.active"> </el-switch>
            <div class="mr-7">
              <div class="text-gray-500">Activer l'article</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="flex justify-start items-center">
            <el-switch class="mr-7" v-model="article.has_sale"> </el-switch>
            <div class="mr-7">
              <div class="text-gray-500">
                L'article est à vendre! moduels : facturation et points de vente
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="flex justify-start items-center">
            <el-switch class="mr-7" c v-model="article.has_purchage">
            </el-switch>
            <div class="mr-7">
              <div class="text-gray-500">
                L'article est à acheté! module achats
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="flex justify-start items-center">
            <el-switch class="mr-7" c v-model="article.has_inventory">
            </el-switch>
            <div class="mr-7">
              <div class="text-gray-500">
                Possiblité de faire l'inventaire de cet article en stock
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="flex justify-start items-center">
            <el-switch class="mr-7" c v-model="article.check_stock">
            </el-switch>
            <div class="mr-7">
              <div class="text-gray-500">
                Contrôler le stock existant de l'article avant le mouvement
              </div>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="bg-white border w-1/3 ml-10 p-4">
        <h3 class="text-lg font-medium border-b pb-1 mb-2">
          Information sur la classification
        </h3>

        <el-form-item label="Entrepôts" prop="warehouses">
          <el-select
            class="w-full"
            v-model="article.warehouses"
            multiple
            placeholder="selectionner la categorie"
          >
            <el-option
              v-for="item in warehouses"
              :key="item.code"
              :label="`${item.name}`"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Categorie" prop="category_id">
          <el-select
            class="w-full"
            v-model="article.category_id"
            filterable
            clearable
            placeholder="selectionner la categorie"
          >
            <el-option
              v-for="item in categories"
              :key="item.id"
              :label="`${item.name}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Unite" prop="uom_id">
          <el-select
            v-model="article.uom_id"
            class="w-full"
            filterable
            clearable
            placeholder="selectionner l'unité de mesure"
          >
            <el-option
              v-for="item in uoms"
              :key="item.id"
              :label="`${item.libelle} - ${item.name}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <h3 class="text-lg font-medium border-b pb-1 mb-2">
          Information sur les prix et stock
        </h3>
        <div class="flex">
          <el-form-item
            class="w-1/2 mr-1"
            label="Prix de vente"
            prop="selling_price"
          >
            <el-input-number
              type="text"
              style="width: 100%"
              :min="0"
              :controls="false"
              v-model.number="article.selling_price"
            />
          </el-form-item>
          <el-form-item class="w-1/2" label="Prix d'achat" prop="average_cost">
            <el-input-number
              :min="0"
              style="width: 100%"
              type="text"
              :controls="false"
              v-model.number="article.average_cost"
            />
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item class="w-1/2 mr-1" label="Stock Maximum">
            <el-input-number
              type="text"
              style="width: 100%"
              :min="0"
              :controls="false"
              v-model.number="article.stock_max"
            />
          </el-form-item>
          <el-form-item class="w-1/2" label="Stock Minimun">
            <el-input-number
              :min="0"
              style="width: 100%"
              type="text"
              :controls="false"
              v-model.number="article.stock_min"
            />
          </el-form-item>
        </div>
        <el-upload
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Déposer les fichiers ici ou<em>cliquez pour envoyer</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            Fichiers jpg/png avec une taille inférieure à 500kb
          </div>
        </el-upload>
      </div></el-form
    >
  </div>
</template>

<script>
import {
  getCategories,
  getUoms,
  createArticle,
  updateArticle,
  nextArticleNumero,
} from "@/api/article";
import { fetchWarehouse } from "@/api/stock";
export default {
  name: "NewArticle",
  props: {
    articleUpdate: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      categories: [],
      uoms: [],
      loading: false,
      statistique: null,
      loadingSku: false,
      warehouses: [],
      article: {
        article_id: undefined,
        article_name: "",
        sku: "",
        remark: "",
        selling_price: 0,
        average_cost: 0,
        stock_max: 0,
        stock_min: 0,
        warning_terms: "",
        has_lot: false,
        has_sale: true,
        has_purchage: true,
        has_inventory: true,
        has_pos: true,
        stock_on_hand: 0,
        check_stock: false,
        active: true,
        barcode: "",
        category_id: null,
        uom_id: null,
        warehouses: [],
      },
      rules: {
        article_name: [
          {
            required: true,
            message: "Le nom de l'article est obligatoire",
            trigger: "blur",
          },
        ],
        sku: [
          {
            required: true,
            message: "L'identifiant  de l'article est obligatoire",
            trigger: "blur",
          },
        ],
        category_id: [
          {
            required: true,
            message: "Veuillez selectionner une categorie",
            trigger: "change",
          },
        ],
        warehouses: [
          {
            required: true,
            message: "Veuillez selectionner les entrepôts",
            trigger: "change",
          },
        ],
        uom_id: [
          {
            required: true,
            message: "Veuillez selectionner une unite",
            trigger: "change",
          },
        ],
        selling_price: [
          {
            type: "number",
            message: "Entrer un chiffre",
            trigger: "blur",
          },
          // { min: 1, message: "Entrer un montant  valide", trigger: "blur" },
        ],
        average_cost: [
          {
            type: "number",
            message: "Entrer un chiffre",
            trigger: "blur",
          },
          //  { min: 1, message: "Entrer un montant valide", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.initData();
    this.fetchWarehouse();
    this.fetchCategorie();
    this.fetchUoms();
    this.handleUpdate();
  },
  methods: {
    async initData() {
      await nextArticleNumero().then((res) => {
        this.loadingSku = true;
        setTimeout(() => {
          this.article.sku = res.data.numero;
          this.loadingSku = false;
          // this.componentLoanding = false;
        }, 1.5 * 1000);
      });
    },
    async fetchCategorie() {
      await getCategories()
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchUoms() {
      await getUoms()
        .then((res) => {
          this.uoms = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
          const warehouse = this.warehouses.find(
            (el) => el.hasPrimary === true
          );
          this.article.warehouses.push(warehouse.id);
        })
        .catch((error) => {
          this.listLoading = false;
          this.$message.error(error.data.message);
        });
    },
    createData(articleForm) {
      this.$refs[articleForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          createArticle(this.article)
            .then(() => {
              setTimeout(() => {
                this.loading = false;

                this.$message({
                  title: "Success",
                  message: "L'article a été enregistré avec succès ",
                  type: "success",
                  duration: 2000,
                });
                this.resetArticle();
                this.initData();
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loading = false;
              if (err.data.status === 400) {
                this.$message({
                  message: "Veuillez renseigner les informations obligatoire",
                  type: "warning",
                });
              } else if (err.data.status === 409) {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "Erreur serveur",
                  type: "danger",
                });
              }
            });
        } else {
          // console.log("hello");
        }
      });
    },
    resetArticle() {
      this.article = {
        article_name: "",
        sku: "",
        remark: "",
        selling_price: 0,
        average_cost: 0,
        warning_terms: "",
        has_lot: false,
        has_sale: true,
        has_purchage: true,
        has_inventory: true,
        has_pos: true,
        check_stock: false,
        stock_max: 0,
        stock_min: 0,
        active: true,
        barcode: "",
        category_id: null,
        uom_id: null,
      };
      this.$nextTick(() => {
        this.$refs["articleForm"].clearValidate();
      });
    },

    handleUpdate() {
      this.resetArticle();
      if (this.articleUpdate !== null) {
        this.article.article_id = this.articleUpdate.article_id;
        this.article.sku = this.articleUpdate.sku;
        this.article.article_name = this.articleUpdate.article_name;
        this.article.remark = this.articleUpdate.remark;
        this.article.selling_price = this.articleUpdate.selling_price;
        this.article.average_cost = this.articleUpdate.average_cost;
        this.article.has_lot = this.articleUpdate.has_lot;
        this.article.has_sale = this.articleUpdate.has_sale;
        this.article.has_purchage = this.articleUpdate.has_purchage;
        this.article.has_inventory = this.articleUpdate.has_inventory;
        this.article.has_pos = this.articleUpdate.has_pos;
        this.article.stock_on_hand = this.articleUpdate.stock_on_hand;
        this.article.check_stock = this.articleUpdate.check_stock;
        this.article.active = this.articleUpdate.is_active;
        this.article.barcode = this.articleUpdate.barcode;
        this.article.category_id = this.articleUpdate.category_id;
        this.article.uom_id = this.articleUpdate.uom_id;
      }
    },
    updateData(articleForm) {
      this.$refs[articleForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          updateArticle(this.article, this.article.article_id)
            .then(() => {
              setTimeout(() => {
                this.loading = false;

                this.$message({
                  title: "Success",
                  message: "L'article a été modifier' ",
                  type: "success",
                  duration: 2000,
                });
                // this.resetArticle();
              }, 1.5 * 1000);
            })
            .catch(() => {
              this.$message({
                title: "warning",
                message: "Veuillez remplir les champs obligatoire ",
                type: "success",
                duration: 2000,
              });
            });
        } else {
          // console.log("hello");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
